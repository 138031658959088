<script setup lang="ts">

type FooterItem = {
  label: string,
  url: string,
}

const footerLink: FooterItem[] = [
  {label: 'Informations légales', url: 'https://www.hyperassur.com/mentions-legales/'},
  {label: 'Fonctionnement du service', url: 'https://www.hyperassur.com/fonctionnement-du-service/'},
  {label: 'Réclamations', url: 'https://www.hyperassur.com/reclamations/'},
  {
    label: 'Traitement de vos données',
    url: 'https://www.hyperassur.com/uploads/2022/01/14.12.2021-Lettre-RGPD_Comparadise_final.pdf'
  },
]
</script>

<template>
  <footer id="footer" class="mb-4 text-gray-500">
    <nav>
      <ul class="flex flex-col md:flex-row justify-start md:justify-center text-sm my-3 mx-5 md:mx-auto gap-5">
        <li v-for="{ label, url } in footerLink" :key="label">
          <a :href="url" target="_blank">{{ label }}</a>
        </li>
      </ul>
    </nav>

    <small class="text-justify md:text-center m-0 mx-5 block">
      Hyperassur est un comparateur d’assurances édité par le groupe Comparadise, intermédiaire en assurance immatriculé
      à l’ORIAS sous le numéro 14004349 et filiale du courtier April.<br> Notre activité est basée sur votre
      consentement. Celui-ci est collecté conformément à la législation en vigueur. <br>Vous pouvez retirer ce
      consentement ou supprimer vos informations personnelles à tout moment à l’adresse mail suivante :
      <a class="text-blue-600 hover:underline" target="_blank" href="mailto:dpo@comparadise.com">dpo@comparadise.com</a> <br> Pour bénéficier du service
      bloctel, <a class="text-blue-600 hover:underline" target="_blank" href="https://www.bloctel.gouv.fr/">suivez ce lien.</a>
    </small>
    <!--    <small v-if="config?.form?.consent_mode" class="text-center mt-3 d-block">-->
    <!--      <a class="btn btn-link" @click="showCookiePreferences" style="font-size: 12px;">Gestion des cookies</a>-->
    <!--    </small>-->
    <slot name="footer"/>
  </footer>
</template>


